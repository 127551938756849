import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { FleetCompany } from '@boosterfuels/types/types-ts'
import {
  UpdateFiscalYearStartMonthPreferencesPayload,
  UpdateStartOfWeekPreferencesPayload,
} from '../models/fleet-company'

@Injectable({ providedIn: 'root' })
export class FleetCompanyApi {
  constructor(private http: HttpClient) {}

  updateStartOfWeekPreferences(payload: UpdateStartOfWeekPreferencesPayload) {
    return this.http.put<{ fleetCompany: FleetCompany }>(
      `/FleetCompany/updateStartOfWeekPreferences`,
      payload
    )
  }

  updateFiscalYearStartMonthPreferences(
    payload: UpdateFiscalYearStartMonthPreferencesPayload
  ) {
    return this.http.put<{ fleetCompany: FleetCompany }>(
      `/FleetCompany/updateFiscalYearStartMonthPreferences`,
      payload
    )
  }
}
